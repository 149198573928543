<template>
  <base-form-item
      :field="field"
      :schemaVersion="schemaVersion"
  >
    <el-select
        :autocomplete="field.meta && field.meta.autocomplete ? field.meta.autocomplete : 'off'"
        :class="checkinputRequired(field.dbName) ? 'validate-error' : ''"
        :clearable="field.meta && field.meta.clearable ? field.meta.clearable : ''"
        :collapse-tags="field.meta && field.meta.collapseTags ? field.meta.collapseTags : false"
        :disabled="field.meta && field.meta.disabled ? field.meta.disabled : false"
        :filterable="typeof field.meta.filterable === 'undefined' ? true : field.meta && !!field.meta.filterable"
        :multiple="field.meta && field.meta.multiple ? field.meta.multiple : false"
        :multiple-limit="field.meta && field.meta.multipleLimit ? field.meta.multipleLimit : 0"
        :name="field.dbName"
        :no-data-text="field.meta && field.meta.noDataText ? field.meta.noDataText : 'No data'"
        :no-match-text="field.meta && field.meta.noMatchText ? field.meta.noMatchText : 'No matching data'"
        :placeholder="field.meta && field.meta.placeholder ? field.meta.placeholder : 'Select'"
        :reserve-keyword="field.meta && field.meta.reserveKeyword ? field.meta.reserveKeyword : false"
        :size="field.meta && field.meta.size ? field.meta.size : ''"
        :value="value"
        v-model="value"
    >
      <el-option
          :key="item.id"
          :label="item.name"
          :value="item.id"
          v-for="item in dictionaries && dictionaries[field.dictionary] ? dictionaries[field.dictionary] : []"
      >
      </el-option>
    </el-select>
    <!--    <small class="help-block" v-if="backend_errors[field.dbName] && !checkinputRequired(field.dbName)">-->
    <!--      <span v-html="validationTranslate(backend_errors[field.dbName][0])"></span>-->
    <!--    </small>-->
  </base-form-item>
</template>

<script>
import abstractForm from '../../mixin/index';
import BaseFormItem from '../../UI/FormItem.vue';

export default {
  mixins: [abstractForm],
  name: 'CreateOrUpdate',
  components: {BaseFormItem},
};
</script>
<style
    lang="scss"
    rel="stylesheet/scss"
    scoped
>

</style>
